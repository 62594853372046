<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="headline">Manage FixIt</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="fixit.term"
                  required
                  label="Search Term"
                  :disabled="disabled"
                  hint="May use a regex"
                  ref="f_term"
                  :rules="[v => !!v || 'Item is required']"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="fixit.catalog_term"
                  required
                  label="Catalog Term or enter URL"
                  ref="f_catalog_term"
                  :rules="[v => !!v || 'Item is required']"
                ></v-text-field>
                <p v-if="isUrl(fixit.catalog_term)">
                  If you enter search term
                  <strong class="text-danger">{{ fixit.term }}</strong>
                  <strong>you will redirect</strong> to URL
                  <strong class="text-success">{{ fixit.catalog_term }}</strong>
                  by click enter on autocomplete.
                </p>
                <p v-else>
                  If you enter search term
                  <strong class="text-danger">{{ fixit.term }}</strong>
                  <strong>you show results</strong> by catalog term
                  <strong class="text-success">{{ fixit.catalog_term }}</strong
                  >.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="" text @click="refresh">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary dark large" ref="submit" @click.prevent="save"
            >Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

import { required } from "vuelidate/lib/validators";
import {
  DELETE_FIXIT,
  PULL_FIXIT_LIST,
  PUT_FIXIT,
  REFRESH
} from "@/core/services/store/fixit.module";
import Swal from "sweetalert2";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function
    }
  },
  data: () => ({
    valid: true,
    dialog: false
  }),
  methods: {
    isUrl: function(s) {
      var urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator
      return !!urlPattern.test(s);
    },
    refresh: function() {
      this.$store.dispatch(REFRESH);
    },
    deleteItem() {
      this.$store.dispatch(DELETE_FIXIT, this.fixit.id).then(() => {
        this.dialog = false;
        this.$store.dispatch(PULL_FIXIT_LIST);
        this.$store.dispatch(REFRESH);
        Swal.fire("Fixit deleted!", "", "success");
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch(PUT_FIXIT, {
            id: this.fixit.id,
            data: {
              catalog_term: this.$refs.f_catalog_term.value,
              term: this.$refs.f_term.value
            }
          })
          .then(() => {
            this.dialog = false;
            this.$store.dispatch(PULL_FIXIT_LIST);
            this.$store.dispatch(REFRESH);
            Swal.fire("Fixit updated!", "", "success");
            if (this.callback) {
              this.callback();
            }
          });
      }
    }
  },
  validations: {
    form: {
      name: { required },
      type_id: { required }
    }
  },
  computed: {
    showDialog() {
      return this.dialog || this.state === "edit";
    },
    ...mapState({
      errors: state => state.fixit.errors,
      message: state => state.fixit.message,
      hasMessage: state => state.fixit.hasMessage,
      fixit: state => state.fixit.fixit,
      state: state => state.fixit.state
    })
  },
  beforeCreate() {
    this.$store.dispatch(REFRESH);
  }
};
</script>
